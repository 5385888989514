$purple: rgba(124,77,255,1);
$red: rgba(232,74,88,1);
$blue: rgba(18, 148, 246, 1);
$green: rgba(91, 190, 190, 1);

$icon-font-path: "/assets/fonts/";

$raleway: 'Raleway',"Helvetica Neue", Helvetica, Arial, sans-serif;
$dosis: 'Dosis','Raleway',"Helvetica Neue", Helvetica, Arial, sans-serif;
$lato: 'Lato','Raleway',"Helvetica Neue", Helvetica, Arial, sans-serif;
$montserrat: 'Montserrat','Raleway',"Helvetica Neue", Helvetica, Arial, sans-serif;
$opensans: 'Open Sans','Raleway',"Helvetica Neue", Helvetica, Arial, sans-serif;
$oswald: 'Oswald','Raleway',"Helvetica Neue", Helvetica, Arial, sans-serif;
$poppins: 'Poppins','Raleway',"Helvetica Neue", Helvetica, Arial, sans-serif;
$roboto: 'Roboto','Raleway',"Helvetica Neue", Helvetica, Arial, sans-serif;
$robotocondensed: 'Roboto Condensed','Raleway',"Helvetica Neue", Helvetica, Arial, sans-serif;
$taviraj: 'Taviraj', Georgia, serif;
$titillium: 'Titillium Web','Raleway',"Helvetica Neue", Helvetica, Arial, sans-serif;
$permanent_marker: 'Permanent Marker', cursive;
$handwritting: 'Yellowtail', cursive;

$primary-color: $red;
$complementary-color: $green;
$button-border-radius: 10px;  // 0px, 10px, 20px
$fullscreen-max-height: 750px;  // 620px - 850px